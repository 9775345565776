.sign-in .firebaseui-container {
  background: none;
  font-family: Archivo, sans-serif;
  box-shadow: none;
  max-width: initial;
}

.sign-in .firebaseui-card-header {
  display: none;
}

.sign-in .firebaseui-input, .sign-in .firebaseui-input-invalid {
  color: white;
}

.sign-in .firebaseui-label {
  color: rgba(255, 255, 255, 0.5);
}

.sign-in .firebaseui-card-content, .sign-in .firebaseui-card-footer {
  padding: 0;
  margin-top: 16px;
}

.sign-in .firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: rgba(155, 157, 161, 0.12);
}

.sign-in .firebaseui-card-actions {
  padding: 0;
}

.sign-in .firebaseui-subtitle, .sign-in .firebaseui-text {
  color: white;
}

.sign-in .firebaseui-input-toggle-on {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_white_24dp.png);
}

.sign-in .firebaseui-input-toggle-off {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_off_white_24dp.png);
}


.sign-in input:-webkit-autofill {
  -webkit-background-clip: text;
}
.sign-in input:-webkit-autofill,
.sign-in input:-webkit-autofill:hover,
.sign-in input:-webkit-autofill:focus,
.sign-in input:-webkit-autofill:active {
  -webkit-box-shadow: none;
}
.sign-in input:-webkit-autofill{
  background-color: rgba(155, 157, 161, 0.12);
  -webkit-text-fill-color: transparent !important;
}