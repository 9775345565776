.progress-bar-container {
  width: 100%;
  position: relative;

  &.small {
    .progress-bar {
      height: 16px;
      border-radius: 2px;
    }
  }

  .progress-bar {
    height: 48px;
    float: left;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 0;
    height: 100%;
    border-radius: 2px;
    overflow: hidden;

    >.progress-bar-track {
      position: relative;
      width: 100%;
      background: #252A2F;
      height: 100%;

      &.black {
        background: #17191E;
      }
    }
  }

  .progress-bar-fill {
    transition: all 1s ease-in-out;
    position: absolute;
    overflow: hidden;
    height: 100%;
    line-height: 20px;
    background: #0366C1;
    z-index: 10;
    border-radius: 2px;
  }
}
