* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
}

body,
html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  color-scheme: dark;
}

.clickable {
  cursor: pointer;
}

a {
  text-decoration: none;
}
