
.squiggly-inline-unnecessary{
    opacity: 1 !important;
}

.line-numbers, .margin-view-overlays, .margin{
    width: 30px !important
}

.editor-scrollable {
    left: 45px !important;
}

