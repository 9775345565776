* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
}

body,
html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  color-scheme: dark;
}

.gradient-link {
  font-weight: 500;
  background: linear-gradient(154.16deg, #31d8bf -3.9%, #0965f1 126.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clickable {
  cursor: pointer;
}

a {
  text-decoration: none;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: none !important;
}