.order-container {
  display: flex;
  height: 100%;
  overflow: auto;
  min-width: 230px;
}

.table {
  overflow: auto;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #3B4046;
}

.row {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  padding: 8px;
}

.row:nth-child(even), .title {
  background-color: #22252C;
}

.row:hover {
  background-color: #787878;
}
